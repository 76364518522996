import { render, staticRenderFns } from "./paragraph_a.vue?vue&type=template&id=4b29f354&scoped=true&"
import script from "./paragraph_a.vue?vue&type=script&lang=js&"
export * from "./paragraph_a.vue?vue&type=script&lang=js&"
import style0 from "./paragraph_a.vue?vue&type=style&index=0&id=4b29f354&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b29f354",
  null
  
)

export default component.exports