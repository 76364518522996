<template>
  <div>
    <div>
      <input
        type="text"
        v-model="data.label"
        :placeholder="$t('form.url.description')"
      />
    </div>
    <div>
      <b>{{ $t("form.url.title") }}</b>
      <input type="text" v-model="data.value" placeholder="https://..." />
      <span class="warning" v-if="error">
        <icon id="triangle-empty-alert" /> {{ $t("form.url.warning") }}
      </span>
    </div>
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    columnWidth() {
      return import("./width.vue");
    }
  },
  props: {},
  data() {
    return {
      data: JSON.parse(
        JSON.stringify(
          this.$store.state.business.unsaved.form[this.$store.state.form.edit]
        )
      ),
      error: false
    };
  },
  watch: {
    "data.value"() {
      this.setSave();
    },
    "data.label"() {
      this.setSave();
    }
  },
  computed: {},
  mounted() {
    this.error = !this.validURL(this.data.value);
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      this.data.description =
        '<a href="' +
        this.data.value +
        '" target="_blank">' +
        this.data.label +
        "</a>";
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
      this.error = !this.validURL(this.data.value);
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
div
  margin-bottom: 10px
.warning
  font-size: 10pt
  color: $danger
input[type=text]
  width: 100%
  padding: 10px
  box-sizing: border-box
  border: 1px solid $success
  &:focus
    outline: none
</style>
